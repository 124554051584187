<template>
  <ul class="tree-content">
    <li v-for="(item, index) in data">
      <div>
        <div>
          <i
            class="icon-gray"
            :class="{
              'sdnm-triangle_right': !item.show,
              'sdnm-triangle_bottom': item.show,
            }"
            v-show="item.children && item.children.length !== 0"
            @click="change(item)"
          ></i>
          <span
            :class="{
              choosed:
                item.id === checkedId &&
                item.children &&
                item.children.length === 0,
              'select-show': item.children && item.children.length !== 0,
            }"
            @click="skip(item)"
            >{{ item.name }}</span
          >
        </div>
        <div v-if="item.children && item.children.length">
          <tree
            :data="item.children"
            v-if="item.show"
            :checked-id="checkedId"
            :name="name"
            :chooseAll="chooseAll"
          ></tree>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
/**
 * @module components/tree
 * @desc 传蔬农贸版 树结构
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-09-27
 * @copyright 2021
 */
import { event } from "../modules/EVENTs";
export default {
  name: "tree",
  props: {
    data: {
      type: Array,
      default: [],
    },
    name: {
      type: [String, Number],
      default: "",
    },
    checkedId: {
      type: [String, Number],
      default: "",
    },
    chooseAll: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  methods: {
    /**
     * 是否显示子类
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-09-30
     */
    change(obj) {
      obj.show = !obj.show;
    },
    /**
     * 点击事件
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-09-30
     */
    skip(obj) {
      if (obj.children && obj.children.length !== 0 && this.chooseAll) {
        obj.show = !obj.show;
      } else {
        event.$emit("sdnm-tree", obj, this.name);
      }
    },
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
</style>
