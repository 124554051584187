<template>
  <div id="searchTree" class="is-comp">
    <input
      type="text"
      class="inp-select"
      :style="{ width: width + 'px' }"
      :class="{ error: tip !== '' }"
      :placeholder="inputPlaceholder"
      readonly
      :value="selected.name"
      @click.stop="showOptions"
    />
    <i class="sdnm-unfold" @click.stop="showOptions"></i>
    <div class="tip">{{ tip }}</div>
    <div class="search-select-options" v-if="optionShow">
      <input
        type="text"
        class="search"
        v-model="keyword"
        :placeholder="searchPlaceholder"
        @keyup.enter="search()"
      />
      <i class="sdnm-search" @click.stop="search()"></i>
      <div class="options">
        <tree
          :data="nodeList"
          :name="name"
          :checked-id="checkedId"
          :chooseAll="chooseAll"
          @sdnm-tree="getChoose"
        >
        </tree>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/searchTree
 * @desc 速商 下拉搜索选择树
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-10-06
 * @copyright 2021
 */
// import tree from '@@/tree';
import tree from "./tree";
import { event } from "../modules/EVENTs";
import { parse } from "../modules/utils";
import { mapGetters, mapActions } from "vuex";
const getters = mapGetters([]);
const actions = mapActions(["setTreeId"]);
export default {
  name: "searchtree",
  computed: {
    ...getters,
  },
  components: { tree },
  props: {
    width: {
      type: Number,
      default: 300,
    },
    choosedInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },
    inputPlaceholder: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "搜索员工名称",
    },
    searchOptions: {
      type: Array,
      default: [],
    },
    tip: {
      type: String,
      default: "",
    },
    name: {
      type: [String, Number],
      default: "",
    },
    chooseAll: {
      type: Boolean,
      default: true,
    }, // true:父类不可点击
  },
  data() {
    return {
      optionShow: false, // 是否显示下拉框
      selected: {}, // 被选中选项的数据
      keyword: "", // 搜索关键字
      searchText: "", // 搜索关键字
      nodeList: [], // 搜索结果返回内容
      checkedId: "", // 选中的选项id
    };
  },
  created() {
    event.$on("sdnm-tree", this.getChoose);
    this.nodeList = this.searchOptions;
  },
  beforeDestroy() {
    event.$off("sdnm-tree", this.getChoose);
  },
  watch: {
    optionShow(newValue, oldValue) {
      if (newValue && !oldValue) {
        window.addEventListener("click", this.windowClick);
      } else {
        window.removeEventListener("click", this.windowClick);
      }
    },
    searchOptions(newValue, oldValue) {
      if (newValue !== "" && newValue !== oldValue) {
        this.nodeList = this.searchOptions;
      }
    },
    choosedInfo(newValue, oldValue) {
      if (newValue !== "") {
        this.checkedId = newValue.id;
        this.selected = newValue;
      } else if (newValue === "") {
        this.checkedId = "";
        this.selected = {};
      }
    },
  },
  methods: {
    ...actions,
    /**
     * 组件界面点击事件处理
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    windowClick(event) {
      let className = event.target.className;
      // console.log(className);
      if (
        className !== "search" &&
        className !== "sdnm-search" &&
        className !== "icon-gray sdnm-triangle_right" &&
        className !== "icon-gray sdnm-triangle_bottom" &&
        className !== "select-show"
      ) {
        if (this.optionShow) {
          this.optionShow = !true;
        }
      }
    },
    /**
     * 回调函数，返回选择的数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    getChoose(obj, name) {
      if (name === this.name) {
        this.selected = obj;
        this.checkedId = obj.id;
        this.$emit("sdnm-select-tree", obj, this.name);
        this.optionShow = false;
      }
    },
    /**
     * 显示下拉框
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    showOptions() {
      this.optionShow = true;
    },
    /**
     * 搜索每一层与关键字匹配的数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    searchEach(node, value) {
      let depth = this.getTreeDepth(node);
      let $this = this;
      for (let i = 0; i < depth - 1; i++) {
        this.traverseTree(node, (n) => {
          if ($this.isHasChildren(n)) {
            let children = n.children;
            let length = children.length;
            let cdHaveValue = children.some((el) => {
              return el.name.indexOf(value) > -1;
            });
            if (!cdHaveValue && n.name.indexOf(value) > -1) {
              n.active = false;
            } else {
              for (let j = length - 1; j >= 0; j--) {
                let e3 = children[j];
                if (!$this.isHasChildren(e3) && e3.name.indexOf(value) <= -1) {
                  children.splice(j, 1);
                }
              }
            }
          }
        });
      }
    },

    /**
     * 搜索数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    search() {
      let $this = this;
      this.nodeList = parse(this.searchOptions);
      if (this.keyword === "") {
        this.searchText = "";
        return;
      }
      if (this.nodeList && this.nodeList.length > 0) {
        this.nodeList.forEach((n, i, a) => {
          $this.searchEach(n, this.keyword);
        });
        let length = this.nodeList.length;
        for (let i = length - 1; i >= 0; i--) {
          let e2 = this.nodeList[i];
          if (!this.isHasChildren(e2) && e2.name.indexOf(this.keyword) <= -1) {
            this.nodeList.splice(i, 1);
          }
        }
        this.searchText = this.keyword;
      }
    },

    /**
     * 判断树形结构中的一个节点是否具有孩子节点
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    isHasChildren(node) {
      let flag = false;
      if (node.children && node.children.length > 0) {
        flag = true;
      }
      return flag;
    },

    /**
     * 通过传入根节点获得树的深度，是 calDepth 的调用者。
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    getTreeDepth(node) {
      if (node === undefined || node === null) {
        return 0;
      }
      let r = 0;
      let currentLevelNodes = [node];
      while (currentLevelNodes.length > 0) {
        r++;
        let nextLevelNodes = [];
        for (let i = 0; i < currentLevelNodes.length; i++) {
          let e = currentLevelNodes[i];
          if (this.isHasChildren(e)) {
            nextLevelNodes = nextLevelNodes.concat(e.children);
          }
        }
        currentLevelNodes = nextLevelNodes;
      }
      return r;
    },

    /**
     * 非递归遍历树
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    traverseTree(node, callback) {
      if (!node) {
        return;
      }
      var stack = [];
      stack.push(node);
      var tmpNode;
      while (stack.length > 0) {
        tmpNode = stack.pop();
        callback(tmpNode);
        if (tmpNode.children && tmpNode.children.length > 0) {
          for (let i = tmpNode.children.length - 1; i >= 0; i--) {
            stack.push(tmpNode.children[i]);
          }
        }
      }
    },
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
#searchTree {
  .pos-r();
  .fs(0);
  .tip {
    .fc(12px, #f84445);
    .pos-a(1, 23px, 0);
  }
  .search-select-options {
    white-space: normal;
    .pos-a(10, 34px, 0);
    .bgc(#ffffff);
    box-shadow: 0 4px 6px 0 #aab1ba;
    .brdr(4px);
    .wh(300px, 200px);
    .mgt(-4px);
    .pd(4px);
    > .search {
      width: 99.6%;
      .mgr(0);
    }
    > .options {
      .mgt(10px);
      .wh(100%, 148px);
      overflow-y: auto;
    }
  }
}
</style>
