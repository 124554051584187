var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"tree-content"},_vm._l((_vm.data),function(item,index){return _c('li',[_c('div',[_c('div',[_c('i',{directives:[{name:"show",rawName:"v-show",value:(item.children && item.children.length !== 0),expression:"item.children && item.children.length !== 0"}],staticClass:"icon-gray",class:{
            'sdnm-triangle_right': !item.show,
            'sdnm-triangle_bottom': item.show,
          },on:{"click":function($event){return _vm.change(item)}}}),_c('span',{class:{
            choosed:
              item.id === _vm.checkedId &&
              item.children &&
              item.children.length === 0,
            'select-show': item.children && item.children.length !== 0,
          },on:{"click":function($event){return _vm.skip(item)}}},[_vm._v(_vm._s(item.name))])]),(item.children && item.children.length)?_c('div',[(item.show)?_c('tree',{attrs:{"data":item.children,"checked-id":_vm.checkedId,"name":_vm.name,"chooseAll":_vm.chooseAll}}):_vm._e()],1):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }